@import "../../styles/mixins.scss";

.blogRecommendation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 12px;
    padding-bottom: 16px;
    color: #2A2E32;


    .subtitle {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.84px;
    }

    .title {
        font-size: 28px;
        font-weight: 700;
        transition: color 0.3s ease-out;
    }

    .description {
        font-size: 20px;
        margin-top: 10px;
    }

    &:hover {
        .title {
            color: #ffaa33;
        }
    }
}