@import '../../styles/mixins.scss';

.post {
	max-width: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;

	&:not(:last-child) {
		margin-bottom: 18px;
	}

	@include media-breakpoint-desktop() {
		height: 100%;
		max-width: 32%;
		gap: 16px;
		margin-bottom: 100px;
		min-height: 630px;
		padding-bottom: 40px;
		position: relative;

		&:not(:last-child) {
			margin-bottom: 100px;
		}
	}
}

.post__img {
	height: 223px;
	width: 100%;
	overflow: hidden;
	margin-bottom: 12px;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	@include media-breakpoint-desktop() {
		margin-bottom: unset;
	}

	@media (hover: hover) {

		&:hover,
		&:focus {
			img {
				transform: scale(1.2);
				transition: transform 500ms ease-in-out;
			}
		}
	}
}

.post__content {
	color: #2a2e32;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-desktop() {
		margin-bottom: auto;
	}
}

.post__tagholder {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.post__subheading {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.84px;
	text-transform: uppercase;
	padding: 4px 8px;
	border: 1px solid #000;
	border-radius: 100px;
	width: fit-content;
	margin-bottom: 10px;

	@include media-breakpoint-desktop() {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.32px;
	}
}

.post__heading {
	color: #2a2e32;
	font-size: 30px;
	font-weight: 600;
	margin-top: 10px;
	display: block;

	@include media-breakpoint-desktop() {
		font-size: 30px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 1.3px;
	}

	@media (hover: hover) {

		&:hover,
		&:focus {
			color: #ffaa33;
		}
	}
}

.post__desc {
	font-size: 18px;
	margin-top: 12px;

	@include media-breakpoint-desktop() {
		font-size: 20px;
		margin-top: 16px;
	}
}

.post__link {
	width: fit-content;
	font-size: 18px;
	font-weight: 600;
	color: #fa3;
	display: flex;
	align-items: center;
	gap: 8px;
	padding-bottom: 2px;

	img {
		padding-top: 4px;
	}

	@media (hover: hover) {

		&:hover,
		&:focus {
			box-shadow: 0 -2px 0 #fa3 inset;
		}
	}

	@include media-breakpoint-desktop() {
		position: absolute;
		bottom: 0;
		left: 0;
	}
}