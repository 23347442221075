@import '../../styles/mixins.scss';

.holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 90px;
    @include media-breakpoint-desktop {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(2, auto);
        place-content: center;
        max-width: 880px;
        margin: 0 auto 120px;
        column-gap: 36px;
    }
}

.image {
    width: 100%;
    max-width: 97px;
    margin-bottom: 30px;
    @include media-breakpoint-desktop {
        max-width: 150px;
        grid-row: 1 / 3;
        margin: 0 36px 0 0;
    }
}

.text {
    margin-bottom: 30px; 
    max-width: 600px;
    text-align: center;
    @include media-breakpoint-desktop {
        text-align: left;
        margin: 10px 0;
    }
}

.link {
    background-color: #FFAA33;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 25px;
    border-radius: 100px;
    color: #2A2E32;
    font-family: inherit;
    transition-property: background-color color;
    transition: .15s ease-out;
    &:hover {
        background-color: #3B79D4;
        color: #fff;
    }
    @include media-breakpoint-desktop {
        margin-right: auto;
    }
}