@import '../../styles/mixins.scss';

.formContainer {
    margin-top: 42px;
    width: auto;

    @include media-breakpoint-desktop {
        margin-top: 0px;
        padding-right: 6rem;
        flex: 1;
        width: 60%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
}

.title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-desktop {
        margin-bottom: 85px;
    }
}

.button {
    @include media-breakpoint-desktop {
        max-width: 245px;
    }
}

.input {
    width: calc(100% - 32px);
    padding: 0px 16px;
    height: 48px;
    font-size: 18px;
    border-radius: 12px;
    border: 0.5px solid #2A2E32;

    @include media-breakpoint-desktop {
        font-weight: 400;
        height: 45px;
        font-size: 20px;
    }
}

.textarea {
    width: calc(100% - 32px);
    padding: 16px;
    height: 190px;
    font-size: 18px;
    border-radius: 12px;
    border: 0.5px solid #2A2E32;
    font-family: 'DM Sans', sans-serif;

    @include media-breakpoint-desktop {
        font-weight: 400;
        font-size: 20px;
        height: 370px;
        border: 0.75px solid #2A2E32;
    }
}

.input::placeholder,
.textarea::placeholder {
    font-weight: 400;
    font-size: 18px;
    color: #2A2E32;

}

.input:required:invalid,
.textarea:required:invalid {
    background-color: white;
}