.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .7);
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: center;
}