@import '../../styles/mixins.scss';

.socialsTitle {
    margin-bottom:32px;
    text-align: center;
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
    @include media-breakpoint-desktop {
        text-align: left;
    }
}

.socialsList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 46px;
    max-width: 500px;
    margin: 0 auto;
    gap: 24px;
    padding: 0;
}

