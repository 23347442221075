@import '../../styles/mixins.scss';

.hero {
	color: #2a2e32;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-inline: auto;
	margin-bottom: 60px;
	@include media-breakpoint-desktop {
		max-width: 875px;
		margin-bottom: 90px;
	}
}

.hero__heading {
	text-align: center;
	font-size: 30px;
	font-weight: 600;
	margin-bottom: 48px;

	@include media-breakpoint-desktop {
		font-size: 34px;
		line-height: 130.5%;
		letter-spacing: 0.68px;
		margin-bottom: 60px;
	}
}

.hero__img {
	display: flex;
	align-items: center;
	gap: 19px;

	.img__heart {
		font-size: 20px;
	}

	@include media-breakpoint-desktop {
		.img__hand {
			width: 144px;
		}

		.img__heart {
			font-size: 33px;
		}
	}
}

.hero__text {
	font-size: 18px;
	margin-top: 48px;
	text-align: center;

	@include media-breakpoint-desktop {
		font-size: 28px;
		font-weight: 500;
		margin-top: 60px;
	}
}
