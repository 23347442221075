@import '../../styles/mixins.scss';

.stats {
    text-align: center;
    margin-bottom: 90px;
    @include media-breakpoint-desktop {
        margin-bottom: 120px;
    }
}

.statsTitle {
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
    margin-bottom: 30px;
    @include media-breakpoint-desktop {
        font-size: 34px;
        line-height: 44px;
        letter-spacing: 0.02em;
        font-weight: 600;
        margin-bottom: 48px;
    }
}

.statsContent {
    @include media-breakpoint-desktop {
        display: flex;
        flex-wrap: wrap;
    }
}

.statsItem {
    margin-bottom: 30px;
    @include media-breakpoint-desktop {
        margin-bottom: 0;
        margin-right: 22px;
        max-width: calc(20% - 22px);
        &:last-child{
            margin-right: 0;
        }
    }
}

.statsCount {
    color: #FFAA33;
    margin-bottom: 8px;
    font-size: 74px;
    line-height: 96px;
    letter-spacing: 0.02em;
    text-align: center;
    font-weight: 500;
    display: flex;
    justify-content: center;
    @include media-breakpoint-desktop {
        font-size: 90px;
        line-height: 117px;
        font-weight: 500;
        margin-bottom: 30px;
    }
}

.statsText {
    font-size: 18px;
    line-height: 23.5px;
    font-weight: 400;
    text-align: center;
    @include media-breakpoint-desktop {
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
    }
}