.socialListItem {
    list-style-type: none;
}

.socialListLink {
    width: 37px;
    height: 37px;
    display: block;
    transition: opacity .3s ease-in-out;
    &:hover {
        opacity: .7;
    }
}

