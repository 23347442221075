@import '../../styles/mixins.scss';

.QRCodeItem {
    margin-bottom: 48px;
    @include media-breakpoint-desktop {
        margin-bottom: 0;
    }
}

.QRCodeTitle {
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
}

.QRCode {
    width: 250px;
    aspect-ratio: 1/1;
    background-color: grey;
    margin: 0 auto;
}

.QRCodeImg {
    display: block;
    width: 100%;
    height: 100%;
}