@import "../../styles/mixins.scss";

.donor {
     > a {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 280px;
        padding: 24px 16px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 15px;
        color: #2A2E32;
        background-color: #FFF8E7;

        &:hover {
            background-color: #FFAA33;
            transition: background-color 0.3s ease-in-out;
        }
    }

    @include media-breakpoint-desktop {
        > a {
            width: 426px;
            font-size: 20px;
            height: 129px;
            align-items: center;
            padding: 0 22px;
        }
    }
}