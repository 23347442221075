@import "../../styles/mixins.scss";

.blogRecommendationContainer {
    padding-top: 60px;

    .title {
        font-size: 20px;
        font-weight: 600;
        color: #2A2E32;
        margin-bottom: 24px;
        text-align: center;
    }

    .blogs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 36px;
        padding: 24px 22px;
        border: 1px solid #2A2E32;
        border-radius: 12px;
    }

    @include media-breakpoint-desktop {
        width: 306px;
        padding-top: 0;
        .title {
            font-size: 28px;
            font-weight: 500;
        }
    }
}