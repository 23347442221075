@import '../../styles/mixins.scss';


.teamMember {
    text-align: center;
    width: 100%;

    .teamMemberName {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }

    @include media-breakpoint-desktop {
        width: auto;

        .teamMemberName {
            font-size: 20px;
            line-height: 26px;
        }
    }
}

.teamMemberImage {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
    overflow: hidden;
    width: 170px;
    height: 170px;
    margin: 0 auto 15px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}