@import '../../styles/mixins.scss';

.container {
    display: flex;
    margin: 60px 0;
    flex-direction: column;

    h1 {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 60px;
    }

    @include media-breakpoint-desktop {
        h1 {
            margin-top: 64px;
            margin-bottom: 0;
        }
    }
}

.title {
    text-align: center;
    font-size: 18px;
    color: #2A2E32;
    font-weight: 600;

    @include media-breakpoint-desktop {
        visibility: hidden;
    }
}

.search {
    border-radius: 12px;
    margin-top: 12px;
    width: calc(100% - 2 * 16px);
    height: 48px;
    border-width: 0.75px;
    padding: 0px 16px;
    margin: 12px auto 0;

    @include media-breakpoint-desktop {
        width: 100%;
    }
}

.search::placeholder {
    visibility: hidden;

    @include media-breakpoint-desktop {
        visibility: visible;
        font-size: 20px;
        color: #2A2E32;
        font-weight: 600;
    }
}

.searchHolder {
    position: relative;
    display: flex;
    justify-content: center;

    @include media-breakpoint-desktop {
        max-width: 754px;
        min-width: 500px;
        margin: 0 auto;
    }
}

.searchLoader {
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    right: 20px;
}