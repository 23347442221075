@import '../../styles/mixins.scss';

.categories {
    margin-bottom: 8px;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #2a2e32;
    text-transform: uppercase;
    &:not(:first-child) {
        margin-left: 15px;
    }
}

.title {
    font-size: 46px;
    line-height: 1.35;
    margin-bottom: 48px;
    letter-spacing: 1px;
}

.rte {
    p {
        font-size: 20px;
        color: #2a2e32;
        font-weight: 400;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    ul,li {
        list-style: initial;
    }
}

.image {
    margin: 48px 0;
    max-width: 100%;
}

.iframe {
    width: 100%;
    aspect-ratio: 16/9;
}