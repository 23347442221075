@import '../../styles/mixins.scss';

.footer {
    background-color: #FFF8E7;
    padding: 121px 25px 70px;

    @include media-breakpoint-desktop {
        padding: 100px 25px 78px;
    }
}

.footerWrap {
    @include media-breakpoint-desktop {
        max-width: 1289px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
}

.footerTitle {
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 24px;

    @include media-breakpoint-desktop {
        text-align: left;
        margin-bottom: 31px;
    }
}

.footerForm {
    width: 100%;
    position: relative;
    max-width: 500px;
    margin: 0 auto 49px;

    @include media-breakpoint-desktop {
        margin: 0;
        max-width: 100%;
    }
}

.footerInput {
    width: 100%;
    height: 46px;
    background-color: #fffefd;
    border: 0.5px solid #2a2e32;
    padding: 14px 23px;
    box-sizing: border-box;
    border-radius: 15px;
}

.footerBtn {
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    display: flex;
    transition: right .3s ease;
    padding: 12px;

    &:hover {
        transition: right .3s ease;
        right: 20px;
    }
}

.footerImg {
    margin: 0 auto 146px;
    display: block;
    max-width: 100%;

    @include media-breakpoint-desktop {
        margin: 0;
        position: absolute;
        top: -41px;
        right: -147px;
    }
}

.footerContent {
    @include media-breakpoint-desktop {
        width: 36.2%;
        position: relative;
    }
}