@import "../../styles/mixins.scss";

.team {
  color: #2a2e32;

  .title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 48px;
  }

  .teamContainer {
    gap: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  @include media-breakpoint-desktop {
    .title {
      text-align: left;
      font-size: 46px;
      line-height: 62px;
      letter-spacing: 5%;
      margin-bottom: 60px;
    }

    .teamContainer {
      gap: 90px;
    }
  }
}