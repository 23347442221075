@import '../../styles/mixins.scss';

.holder {
    margin-bottom: 90px;
    @include media-breakpoint-desktop {
        margin-bottom: 120px;
    }
}

.title {
    font-size: 24px;
    font-weight: 700;
    color: #2A2E32;
    text-align: center;
    margin-bottom: 60px;
    @include media-breakpoint-desktop {
        font-size: 34px;
        font-weight: 600;
        letter-spacing: 0.6px;
    }
}

.goals {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.goal {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    @media only screen and (max-width: 1199px) {
        &:not(:last-child) {
            margin-bottom: 60px;
        }
    }
    @include media-breakpoint-desktop {
        flex: 1 1 32%;
        flex-direction: column;
        &:not(:first-child) {
            margin-left: 2%;
        }
    }
}

.image {
    margin-bottom: 30px;
    @include media-breakpoint-desktop {
        margin-bottom: 48px; 
        min-width: 247px;
    }
}

.text {
    font-size: 18px;
    color: #2A2E32;
    font-weight: 600;
    text-align: center;
    @media only screen and (max-width: 1199px) {
        max-width: 80%;
    }
    @include media-breakpoint-desktop {
        font-size: 28px;
        font-weight: 500;
    }
}