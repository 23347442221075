@import '../../styles/mixins.scss';

.post {
	max-width: 100%;
	width: 100%;

	@include media-breakpoint-desktop {
		max-width: 49%;
		display: flex;
		align-items: flex-start;
		gap: 16px;
		padding-bottom: 30px;
	}
}

.post__img {
	height: 223px;
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	margin-bottom: 12px;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	@include media-breakpoint-desktop {
		max-height: unset;
		margin-bottom: unset;
	}

	@media (hover: hover) {

		&:hover,
		&:focus {
			img {
				transform: scale(1.2);
				transition: transform 500ms ease-in-out;
			}
		}
	}
}

.post__content {
	color: #2a2e32;
	display: flex;
	flex-direction: column;
	height: 100%;

	@include media-breakpoint-desktop {
		flex: 0 0 286px;
	}
}

.post__tagholder {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.post__subheading {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.84px;
	text-transform: uppercase;
	padding: 4px 8px;
	border: 1px solid #000;
	border-radius: 100px;
	width: fit-content;
	margin-bottom: 10px;

	&:not(:last-child) {
		margin-right: 10px;
	}
}

.post__heading {
	color: #2a2e32;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 12px;

	@include media-breakpoint-desktop {
		font-size: 30px;
		font-weight: 600;
	}

	@media (hover: hover) {

		&:hover,
		&:focus {
			color: #ffaa33;
		}
	}
}

.post__desc {
	font-size: 18px;
}