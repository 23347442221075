@import '../../styles/mixins.scss';

.wrapper{
    max-width: 316px;
}
.wrapperElement{
    margin-bottom: 30px;
}
.namePropery{
    font-weight: 600;
    font-size: 18px;
    color: #2A2E32;
    @include media-breakpoint-desktop {
        font-size: 20px;
    }
}

.text{
    font-weight: 400;
    font-size: 18px;
    color: #2A2E32;
    white-space: pre;

    @include media-breakpoint-desktop {
        font-size: 20px;
    }
}

.title {
    font-weight: 600;
    font-size: 30px;
    margin-top: 60px;
    margin-bottom: 30px;
    
    @include media-breakpoint-desktop {
        margin-top: 0px;
        font-size: 34px;
    }
}